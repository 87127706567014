import React, { useEffect, useRef, useState } from 'react';
import 'mapbox-gl/dist/mapbox-gl.css';
import s from './style.module.less';
import { renderToStaticMarkup } from 'react-dom/server';
import cN from 'classnames';
import { contentfulJsonToHtml } from '../utils';
import Logo from '../Logo';

let mapboxgl;

if (!process.env.STATIC) {
  mapboxgl = require('mapbox-gl');
  mapboxgl.accessToken =
    'pk.eyJ1IjoiYW55a2V5IiwiYSI6ImNrM3JkZ2IwMDBhZHAzZHBpemswd3F3MjYifQ.RLinVZ2-Vdp9JwErHAJz6w';
}

export default ({ recordings }) => {
  const mapRef = useRef(null);
  const [hightlightedRecordingId, setHightlightedRecordingId] = useState(null);
  const [playingRecordId, setPlayingRecordId] = useState(null);

  const [map, setMap] = useState(null);

  useEffect(() => {
    const mapboxInstance = new mapboxgl.Map({
      container: mapRef.current,
      style: 'mapbox://styles/mapbox/streets-v9',
    }).addControl(new mapboxgl.NavigationControl(), 'top-left');

    recordings.forEach(recording => {
      if (recording.location) {
        const marker = new mapboxgl.Marker({ color: '#000' })
          .setLngLat([recording.location.lon, recording.location.lat])
          .addTo(mapboxInstance);
        const popup = new mapboxgl.Popup()
          .setHTML(renderToStaticMarkup(<PopupContent {...recording} />))
          .on('open', (foo, faa) => {
            setHightlightedRecordingId(recording.id);
            setPlayingRecordId(recording.id);

            // highlightedPoint.push(location);
            // setHighlightedPoint([...highlightedPoint]);
          })
          .on('close', () => {
            // highlightedPoint.shift();
            // setHighlightedPoint([...highlightedPoint]);
          });
        marker.setPopup(popup);
      }
    });

    setMap(mapboxInstance);

    return () => {
      mapboxInstance.remove();
    };
  }, [recordings]);

  const flyTo = id => {
    setHightlightedRecordingId(id);
    const location = recordings.find(recording => id === recording.id).location;
    map.flyTo({ center: [location.lon, location.lat], zoom: 13, speed: 1 });
  };

  return (
    <div className={s.container}>
      <div ref={mapRef} className={s.map} />
      <List
        recordings={recordings}
        hightlightedRecordingId={hightlightedRecordingId}
        flyTo={flyTo}
        playingRecordId={playingRecordId}
        setPlayingRecordId={setPlayingRecordId}
      />
    </div>
  );
};

const PopupContent = ({ title }) => {
  return <div>{title}</div>;
};

const List = ({
  recordings,
  hightlightedRecordingId,
  flyTo,
  playingRecordId,
  setPlayingRecordId,
}) => {
  return (
    <div className={s.listContainer}>
      <div className={s.logoWrapper}>
        <Logo />
      </div>
      <ul className={s.list}>
        {recordings.map(item => {
          return (
            <ListItem
              key={item.id}
              hightlightedRecordingId={hightlightedRecordingId}
              playingRecordId={playingRecordId}
              setPlayingRecordId={setPlayingRecordId}
              flyTo={flyTo}
              {...item}
            />
          );
        })}
      </ul>
    </div>
  );
};

function ListItem({
  title,
  date,
  id,
  sound,
  comment,
  hightlightedRecordingId,
  playingRecordId,
  setPlayingRecordId,
  flyTo,
}) {
  const audioEl = useRef(null);
  const listEl = useRef(null);

  useEffect(() => {
    if (sound) {
      if (playingRecordId === id) {
        audioEl.current.play();
      } else {
        if (!audioEl.current.paused) {
          audioEl.current.pause();
        }
      }
    }
  }, [playingRecordId, id, sound]);

  useEffect(() => {
    if (hightlightedRecordingId === id) {
      listEl.current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      });
    }
  }, [hightlightedRecordingId, id]);

  useEffect(() => {
    if (sound) {
      function setIsPlaying() {
        setPlayingRecordId(id);
      }
      const el = audioEl.current;
      el.addEventListener('play', setIsPlaying);
      return () => {
        el.removeEventListener('play', setIsPlaying);
      };
    }
  }, [id, sound, setPlayingRecordId]);

  return (
    <li
      className={cN(s.listItem, {
        [s.highlighted]: id === hightlightedRecordingId,
      })}
      ref={listEl}
    >
      <h1>
        <button
          className={s.titleButton}
          onClick={() => {
            if (audioEl) {
              audioEl.current.play();
            }
            flyTo(id);
            setPlayingRecordId(id);
          }}
        >
          {title}
        </button>
      </h1>
      {date && <div className={s.date}>{formateDate(date)}</div>}
      {comment && id === hightlightedRecordingId && (
        <div className={s.comment}>{contentfulJsonToHtml(comment.json)}</div>
      )}
      {/* eslint-disable jsx-a11y/media-has-caption */}
      {sound && <audio ref={audioEl} controls src={sound.file.url} />}
      {/* eslint-enable jsx-a11y/media-has-caption */}
    </li>
  );
}

function formateDate(date) {
  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  return new Date(date).toLocaleDateString('de-DE', options);
}
